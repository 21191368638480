<template>
    <a-card>
        <a-page-header
            :title='"广告主管理看板"'
        />

        <div class="mb-20">平台账套异常分析</div>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => index"
            :pagination='false'
        >
        </a-table>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '',
                    dataIndex: 'title',
                    width: '34%'
                },
                {
                    align: 'center',
                    title: '广告主状态开启',
                    dataIndex: 'stateOn',
                    width: '33%',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主状态关闭',
                    dataIndex: 'stateOff',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        getDataList() {
            this.loading = true
            this.$api.core.pphtManage.getAdvertiserOauthData().then((res) => {
                this.loading = false
                if(res.code == 200) {
                    let _data = res.data
                    this.dataList = [
                        { title: '广告主数', stateOn: _data.advertiserSumByOpenStatus, stateOff: _data.advertiserSumByCloseStatus },
                        { title: '巨量广告授权中的广告主数', stateOn: _data.advertiserAuthSumByOpenStatus, stateOff: _data.advertiserAuthSumByCloseStatus },
                        { title: '有托管巨量广告平台帐套的广告主数', stateOn: _data.oceanAccountSumByOpenStatus, stateOff: _data.oceanAccountSumByCloseStatus },
                    ]
                } else {
                    this.dataList = []
                    console.error(`获取列表失败${res}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.mb-20 {
    margin-bottom: 20px;
}
</style>